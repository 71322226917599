// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-static-page-js": () => import("/home/lynch/projects/JS/mes-group-10/src/templates/static-page.js" /* webpackChunkName: "component---src-templates-static-page-js" */),
  "component---src-pages-index-js": () => import("/home/lynch/projects/JS/mes-group-10/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-main-contacts-js": () => import("/home/lynch/projects/JS/mes-group-10/src/pages/main/contacts.js" /* webpackChunkName: "component---src-pages-main-contacts-js" */),
  "component---src-pages-main-faq-js": () => import("/home/lynch/projects/JS/mes-group-10/src/pages/main/faq.js" /* webpackChunkName: "component---src-pages-main-faq-js" */),
  "component---src-pages-main-jobs-js": () => import("/home/lynch/projects/JS/mes-group-10/src/pages/main/jobs.js" /* webpackChunkName: "component---src-pages-main-jobs-js" */),
  "component---src-pages-main-news-js": () => import("/home/lynch/projects/JS/mes-group-10/src/pages/main/news.js" /* webpackChunkName: "component---src-pages-main-news-js" */)
}

