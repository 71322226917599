module.exports = [{
      plugin: require('/home/lynch/projects/JS/mes-group-10/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":400},
    },{
      plugin: require('/home/lynch/projects/JS/mes-group-10/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/lynch/projects/JS/mes-group-10/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://mes-group.ru"},
    },{
      plugin: require('/home/lynch/projects/JS/mes-group-10/node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"37367360","webvisor":true,"trackHash":true},
    },{
      plugin: require('/home/lynch/projects/JS/mes-group-10/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
